import { List } from '@react95/core'

const RightClick = (props: { x: number, y: number }) => {

    console.log('@rightClick', props.x, props.y)

    return (
        <div style={{
            position: 'absolute',
            top: props.y,
            left: props.x,
            zIndex: 500
        }}>
            <List>
                <List.Item>View</List.Item>
                <List.Divider />
                <List.Item>Customize this Folder...</List.Item>
                <List.Divider />
                <List.Item>Arrange Icons</List.Item>
                <List.Item>Line Up Icons</List.Item>
                <List.Divider />
                <List.Item>Refresh</List.Item>
                <List.Divider />
                <List.Item>Paste</List.Item>
                <List.Item>Paste Shortcut</List.Item>
                <List.Item>Undo Copy</List.Item>
                <List.Divider />
                <List.Item>New</List.Item>
                <List.Divider />
                <List.Item>Properties</List.Item>
            </List>
        </div>
    )
}

export default RightClick