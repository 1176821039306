import { Modal } from '@react95/core'
import { Confcp118 } from '@react95/icons'
import '@react95/icons/icons.css'
import { isMobile } from 'react-device-detect'


const SeeMore = (props: any) => {

    return (
        <Modal
            defaultPosition={{
                x: isMobile ? 25 : 100,
                y: isMobile ? 35 : 625,
            }}
            icon={<Confcp118 variant="32x32_4" />}
            title="Warning"
            closeModal={() => props.setOpen(false)}
            width={ isMobile ? (window.innerWidth - 50).toString() : '500'}
            height="135"
            buttons={[
                { value: 'Ok', onClick: () => props.setOpen(false) },
            ]}

        >
            <p style={{
                textAlign: 'center',
            }}>
                { 'Remember to check my projects that can be found under ' } <br /><b>{ 'start menu -> projects' }</b>
            </p>

        </Modal>
    )


}
    
export default SeeMore
