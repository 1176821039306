import { List, Modal, Fieldset } from '@react95/core'
import { Icwdial102 } from '@react95/icons'
import '@react95/icons/icons.css'
import { isMobile } from 'react-device-detect'


const ContactModal = (props: any) => {

    return (
        <Modal
            defaultPosition={{
                x: isMobile ? 25 : 50,
                y: isMobile ? 25 : 50,
            }}
            icon={<Icwdial102 variant="32x32_4" />}
            title="The Syntax of Me"
            closeModal={() => props.setOpen(false)}
            width={ isMobile ? (window.innerWidth - 50).toString() : '600'}
            height="500"
            buttons={[
                { value: 'Ok', onClick: () => props.setOpen(false) },
                { value: 'Cancel', onClick: () => props.setOpen(false) },
            ]}
            menu={[
                {
                    name: 'File',
                    list: (
                        <List>
                            <List.Item onClick={() => props.setOpen(false)}>Exit</List.Item>
                        </List>
                    ),
                },
            ]}

        >
            <Fieldset
                style={{
                    position: 'relative',
                    height: '100%',
                }}
                legend='Who Am I?'
            >
                {/* <TextArea
                    rows={30}
                    cols={75}
                > */}
                <div style={{
                    height: isMobile ? '350px' : '380px',
                    overflowY: 'scroll',

                }}>
                    Hi, I'm Konsta, and implementing software development as a lifestyle (SdaaL) is my passion. I've been fascinated by technology ever since I was a child, and it all started with my love for electronics. I was particularly drawn to phones and the way they worked, and that curiosity led me to start tinkering with electronics and computers at an early age.
                    <br />
                    <br />
                    Then life took the software development path. Over time, I have worked on numerous personal projects. I also have three years of job experience as a software developer and because of that I have gained experience with a variety of programming languages from developing full-stack applications to embedded software development. Through these projects, I've developed a strong foundation in software development and have learned how to tackle challenges.
                    <br />
                    <br />
                    While I eventually chose software development as my career path, I've never lost my interest in hardware. In fact, I continue to pursue my hobby of hacking and tinkering with old analog telephones and radios because of my enthusiasm of being a ham radio operator.
                    <br />
                    <br />
                    Thanks for taking the time to get to know me! If you're interested in learning more about my programming experience, technical skills, coding philosophy, and future goals, feel free to explore my website where you can find a list of some of my bigger projects and remember to check out my GitHub profile! I'm always looking for new challenges and opportunities to learn and grow, and I'm excited to see where my programming journey will take me next.
                </div>
                {/* </TextArea> */}
            </Fieldset>

        </Modal>
    )


}
    
export default ContactModal
