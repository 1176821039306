import React from 'react'
import { isMobile } from 'react-device-detect'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const BSoD_img = require('../images/BSoD.png')

const BSoD = () => {

    const bsod_s: any = {
        zIndex: 1000,
        position: 'absolute',
        height: '100vh', 
        width: '100vw',
        backgroundColor: '#001ca4',
        display: 'flex',
    }

    const bsod_img_s = {
        height: '70%',
        margin: 'auto',
    }

    const bsod_img_s_mobile = {
        width: '100%',
        margin: 'auto',
    }

    return (
        <div style={ bsod_s }>
            {
                !isMobile
                    ? <img src={BSoD_img} alt="BSoD" style={bsod_img_s}></img>
                    : <img src={BSoD_img} alt="BSoD" style={bsod_img_s_mobile}></img>
            }
        </div>
    )
}

export default BSoD