import { useState } from 'react'
import { List, Modal, Tree, Frame } from '@react95/core'
import { FileText, Icwdial102 } from '@react95/icons'
import '@react95/icons/icons.css'
import { isMobile } from 'react-device-detect'


const ProjectsModal = (props: any) => {


    const prjDescs = [
        
        // WATCHDOG
        {
            id: 1,
            link: 'https://github.com/komppa/Watchdog',
            short: 'Watchdog',
            label: 'Watchdog - Tracking application for nrf9160 using MERN-stack',
            text: '"Watchdog" is a tracking application that I developed using the MERN stack. The application utilizes the nRF9160 development board to track real-time location data and display it on an interactive map. Additionally, the project includes arm and disarm commands that can be executed remotely from the React dashboard to the device, as well as the ability to get sensor details and battery charge information. This project allowed me to explore the capabilities of the MERN stack and gain experience with embedded systems development.',
        },

        // ft918_web_interface
        {
            id: 2,
            short: 'FT817 JS lib',
            link: 'https://github.com/komppa/ft817_web_interface',
            label: 'ft918 web interface - NodeJS library to control ham radio rig Yeasu FT817',
            text: '"ft817_web_interface" is a NodeJS library that I developed to control the Yeasu FT817 ham radio rig. The library allows users to interact with the rig\'s various functions, such as setting the frequency, mode, and power output. This project allowed me to combine my passion for software development with my interest in amateur radio, and gave me the opportunity to explore the serial communication capabilities of NodeJS',
        },

        // WIN95REACT
        {
            id: 3,
            link: 'https://github.com/komppa/win95react',
            short: 'Win95React',
            label: 'win95react - Windows 95 inspired desktop implemented in React',
            text: '"win95react" is a project that I developed to challenge my web development skills by implementing a Windows 95-inspired desktop environment using React. The project required me to carefully consider UI design and user experience, as well as utilize classes to manage the state of each window.'
        },

        // TV Guide
        {
            id: 4,
            short: 'TV Guide',
            link: '',
            label: 'TV Guide - easy to use EPG',
            text: '"TV Guide" is an easy-to-use electronic program guide (EPG). The project was designed to be a very lightweight and optimized for mobile devices, allowing users to quickly and easily find the programming they are looking for. This project allowed me to explore the design principles of user interfaces for mobile devices and creating responsive and mobile-first web designs. At the moment, the source code for this project is not open since it would probably need a magic reveal of the backend code as well that cannot be currently shared.',
        },

        // CONNECTOPENVPN
        {
            id: 5,
            link: 'https://github.com/komppa/connect-openvpn',
            short: 'ConnOpenVPN',
            label: 'Connect-openvpn - ensure OpenVPN client to be connected to the VPN server',
            text: '',
        },

        // LRC v1
        {
            id: 6,
            link: 'https://github.com/komppa/LoRaChat_v4',
            short: 'LoRaChat v1',
            label: 'LRC v1 (LoRaChat) - Chat without internet with your friends using mesh of LoRa radios on ESP32 (MVP)',
            text: '"LRC v1 (LoRaChat)" is a project that I developed to create a chat application that works without an internet connection. NOTE: the newest version of the project is v4 - check link! The app uses a mesh of LoRa radios on the ESP32 microcontroller to allow users to communicate with their friends even when they don\'t have access to Wi-Fi or cellular data. This project was a Minimum Viable Product (MVP) that was pretty barebones, but it worked. It allowed me to explore the capabilities of the LoRa protocol and to experiment with wireless communication using the ESP32.',
        },

        // LRC v2
        {
            id: 7,
            link: 'https://github.com/komppa/LoRaChat_v4',
            short: 'LoRaChat v2',
            label: 'LRC v2 (LoRaChat) - Chat without internet with your friends using mesh of LoRa radios on ESP32 (BAREBONE)',
            text: '"LRC v2 (LoRaChat)" is a project that I developed to improve upon the original version of my chat application, "LRC v1", which allows users to communicate with their friends without an internet connection using a mesh of LoRa radios on the ESP32 microcontroller. NOTE: the newest version of the project is v4 - check link! This new version is still a barebones MVP, but it features improved stability and usability compared to the first version. '
        },

        // LRC v3
        {
            id: 8,
            link: 'https://github.com/komppa/LoRaChat_v4',
            short: 'LoRaChat v3',
            label: 'LRC v3 (LoRaChat) - Chat without internet with your friends using mesh of LoRa radios on ESP32 (TOO COOL TO CONTINUE)',
            text: '"LRC v3 (LoRaChat)" is a project that I developed to continue improving upon the previous versions of my chat application, "LRC v1" and "LRC v2". NOTE: the newest version of the project is v4 - check link! This version of the application features a range of exciting new features and enhancements, including the ability to have both two global group chats as well as private chats, the ability to link backbone repeaters to users\' radios, and the ability to accept messages from analog UHF handheld radios using DTMF and text-to-speech. Additionally, this version provides a very good user experience on the chat interface and displays signal-to-noise ratios (SNRs) to other users. One of the major new features of this version is the development of the OFFNET feature, which allows offline "web server"-like devices to connect to the LRC network. This innovative new feature enables users to access the chat functionality even when they don\'t have access to a live internet connection, greatly increasing the reliability and availability of the network. The app also features emergency messages, which can be used to ensure reliable communication during emergency situations. These messages are prioritized over other messages on the network, allowing users to quickly send out critical information even in the event of network congestion. This project allowed me to continue to refine my skills in software design and hardware integration, and to explore new possibilities for the use of the LoRa protocol in emergency communication.',
        },

        // LRC v4
        {
            id: 18,
            link: 'https://github.com/komppa/LoRaChat_v4',
            short: 'LoRaChat v4',
            label: 'LRC v4 (LoRaChat) - The Ultimate Off-Grid Communications Solution (the new beginning)',
            text: '"LRC v4 (LoRaChat)" - LRC v4 (LoRaChat) is a completely rewritten off-grid chat application, enabling seamless communication without internet. Focusing on performance and usability, this version features custom loaders for a smoother web-based UI and on-the-fly parameterization. LRC v4 offers versatile connectivity options, including existing Wi-Fi access points or creating its own on-the-go. Designed for emergency communication using LoRa radios on the ESP32 microcontroller, LRC v4 revolutionizes off-grid communication.' 
        },

        // LRC DFU
        {
            id: 19,
            link: 'https://github.com/komppa/LoRaChat_DFU',
            short: 'LoRaChat DFU',
            label: 'LRC DFU (LoRaChat Device Firmware Update) - Update your LRC device firmware using web browser',
            text: '"LRC DFU (LoRaChat Device Firmware Update)" - LRC DFU is a application for updating LRC devices firmware using web browser. LRC DFU is designed to be used with LRC v4. It can fetch the latest release from GitHub and update the device firmware using just web browser. By using LRC DFU the bootloader, custom partition table, firmware itself, and web-based user interface will be updated without any hassle. LRC DFU can be also used to update parameters of the device, such as Wi-Fi credentials, LoRa settings, and more. Source code can be found on github.com/komppa/LoRaChat_DFU and the live site can be found from firmware.rantakangas.com.' 
        },

        // LRC REPEATER
        {
            id: 9,
            link: '',
            short: 'LRC backbone',
            label: 'LRC (LoRaChat) repeater - Smart repeater system for high power backbone for LRC',
            text: '',
        },

        // LRC UHF
        {
            id: 10,
            link: '',
            short: 'LRC UHF',
            label: 'LRC UHF (LoRaChat) - Write and receive messages on LRC network using DTMF capability of analog FM handheld (virtual telephone operator)',
            text: '',
        },

        // CODE64
        {
            id: 11,
            link: '',
            short: 'Code64',
            label: 'Code64 - VSCode extension for faster Commodore 64 development with KickAssembler and VICE',
            text: '',
        },

        // FT8SHACK
        {
            id: 12,
            link: 'https://github.com/komppa/FT8Shack',
            short: 'FT8Shack',
            label: 'FT8Shack - Modulate and demodulate FT8 messages to/from audio channel on Android',
            text: '',
        },

        // KUITUMAPPER
        {
            id: 13,
            link: 'https://kuitu.rantakangas.com',
            short: 'Kuitumapper',
            label: 'Kuitumapper - Crawls and plots on map where optical fiber is available',
            text: '',
        },

        // TEMPCO 2 MQTT
        {
            id: 14,
            link: 'https://github.com/komppa/tempco2mqtt',
            short: 'tempco2mqtt',
            label: 'tempco2mqtt - Control, view & manage Yali Digial smart heaters from Home Assistant',
            text: '',
        },

        // ROVAKAIRA API
        {
            id: 15,
            link: 'https://github.com/komppa/rovakairaAPI',
            short: 'RovakairaAPI',
            label: 'RovakairaAPI - Access and view your electric smart meter for Rovakaira customers',
            text: '',
        },

        // ATS
        {
            id: 16,
            link: 'https://github.com/komppa/ATS',
            short: 'ATS',
            label: 'ATS (Automatic Transfer Switch) - Intelligent power switching between two power sources',
            text: '',
        },

        // MISC
        {
            id: 17,
            link: '',
            short: 'MISC',
            label: 'A massive pile of other misc projects as well',
            text: '',
        },
    ]

    const [selectedProject, setSelectedProject] = useState(1)

    const treeNodesArr: { id: any; label: string; onClick: () => void; icon: JSX.Element; }[] = []

    prjDescs.forEach((p: any) => {
        treeNodesArr.push({
            id: p.id,
            label: p.short,
            onClick: () => setSelectedProject(p.id),
            icon: <FileText variant="32x32_4" />
        })
    })

    const treeNodes = {
        data: treeNodesArr
    }

    const window_wrapper_s = {
        height: '95%',  // To leave space for OK and CANCEL buttons
        display: 'flex',
        top: '0',
    }

    const tree_s = {
        flex: '1',
        paddingRight: '5px',
        top: '0',
    }

    const content_s = {
        width: '70%',
        top: '0',
    }

    // window.open('https://github.com/komppa', '_blank', 'noreferrer');
    // <a href={prjDescs.filter((p: any) => p.id === selectedProject)[0].link}>Source code</a>

    return (
        <>
            <Modal
                defaultPosition={{
                    x: isMobile ? 25 : 50,
                    y: isMobile ? 25 : 50,
                }}
                icon={<Icwdial102 variant="32x32_4" />}
                title="Projects"
                closeModal={() => props.setOpen(false)}
                width={ isMobile ? (window.innerWidth - 50).toString() : '600'}
                height={ isMobile ? (window.innerHeight - 200).toString() : '600'}
                buttons={[
                    { value: 'Ok', onClick: () => props.setOpen(false) },
                    { value: 'Cancel', onClick: () => props.setOpen(false) },
                ]}
                menu={[
                    {
                        name: 'File',
                        list: (
                            <List>
                                <List.Item onClick={() => props.setOpen(false)}>Exit</List.Item>
                            </List>
                        ),
                    },
                ]}

            >
                <div style={ window_wrapper_s }>
                    <div style={tree_s}>
                        <Frame h="100%" boxShadow="in" bg="white">
                            <div style={{
                                padding: '2px',
                            }}>
                                <Tree
                                    { ...treeNodes }
                                />
                            </div>
                        </Frame>
                    </div>
                    <div style={content_s}>
                        <Frame h="100%" boxShadow="in" bg="white">
                            <div style={{
                                padding: '2px',
                                height: isMobile ? (window.innerHeight - 340).toString() + 'px' : '450px',
                                overflowY: 'scroll'

                            }}>
                                <h2>
                                    { prjDescs.filter((p: any) => p.id === selectedProject)[0].label }
                                </h2>

                                {
                                    prjDescs.filter((p: any) => p.id === selectedProject)[0].link !== '' &&
                                    <a href="#" onClick={() => window.open(prjDescs.filter((p: any) => p.id === selectedProject)[0].link, '_blank', 'noreferrer')}>Source code</a>
                                }
                                
                                <p>
                                    { prjDescs.filter((p: any) => p.id === selectedProject)[0].text }
                                </p>
                            </div>
                        </Frame>
                    </div>
                </div>
            </Modal>
        </>
    )


}
    
export default ProjectsModal
