import { List, Modal, Fieldset } from '@react95/core'
import { Icwdial102 } from '@react95/icons'
import '@react95/icons/icons.css'
import { isMobile } from 'react-device-detect'


const ContactModal = (props: any) => {

    return (
        <Modal
            defaultPosition={{
                x: isMobile ? 25 : 50,
                y: isMobile ? 25 : 50,
            }}
            icon={<Icwdial102 variant="32x32_4" />}
            title="Contact information"
            closeModal={() => props.setOpen(false)}
            width={ isMobile ? (window.innerWidth - 50).toString() : '600'}
            height="225"
            buttons={[
                { value: 'Ok', onClick: () => props.setOpen(false) },
                { value: 'Cancel', onClick: () => props.setOpen(false) },
            ]}
            menu={[
                {
                    name: 'File',
                    list: (
                        <List>
                            <List.Item onClick={() => props.setOpen(false)}>Exit</List.Item>
                        </List>
                    ),
                },
            ]}

        >
            <Fieldset
                style={{
                    position: 'relative',
                    height: '100%',
                }}
                legend='Contact information'
            >
                <br />
                <div style={{
                    height: isMobile ? '50px' : '80px',
                    overflowY: 'scroll',

                }}>
                    posti [at] rantakangas.com
                </div>
                {/* </TextArea> */}
            </Fieldset>

        </Modal>
    )


}
    
export default ContactModal
