import React from 'react'
import { useState, useEffect } from 'react'
import { ThemeProvider, TaskBar, List } from '@react95/core'
import { Computer2, Icwdial102, Winfile1, Awfxcg321301 } from '@react95/icons'
import '@react95/icons/icons.css'
import { isMobile } from 'react-device-detect'
import ContactModal from './modals/Contact'
import Contact2Modal from './modals/Contact2'
import ProjectsModal from './modals/Projects'
import BSoD from './views/BSoD'
import RightClick from './components/RightClick'
import SeeMore from './modals/SeeMore'


// eslint-disable-next-line @typescript-eslint/no-var-requires
const github_img = require('./images/gh.png')

const App = () => {

    // Windows
    const [contactOpen, setContactOpen] = useState(false)
    const [projectsOpen, setProjectsOpen] = useState(false)
    const [contact2Open, setContact2Open] = useState(false)
    const [shutdownOpen, setShutdownOpen] = useState(false)

    // Right click
    const [rightClick, setRightClick] = useState({ show: false, x: 0, y: 0 })
    // See more window open
    const [seeMoreOpen, setSeeMoreOpen] = useState(true)


    const pleaseBsod = () => {
        // "Reset" all windows
        // setContactOpen(false)
        // setProjectsOpen(false)
        // setShutdownOpen(false)
        // setRightClick({ show: false, x: 0, y: 0 })
        // Change useState to rerender page and show blue screen of death
        setShutdownOpen(true)
        // When few seconds has passed, "release" view
        setTimeout(() => setShutdownOpen(false), 3000)
    }


    useEffect(() => {
        
        window.addEventListener('contextmenu', (e) => {
            e.preventDefault()
            setRightClick({
                show: true,
                x: e.clientX,
                y: e.clientY
            })

            // Add listener that waits when right click menu can be vanished
            window.addEventListener('click', (e) => {
                e.preventDefault()
                setRightClick({ show: false, x: 0, y: 0 })
                return false
            })

            return false

        }, false)
    }, [])
    

    return (
        <ThemeProvider>
            

            { contactOpen && <ContactModal setOpen={(state: boolean) => setContactOpen(state)}/> }
            { contact2Open && <Contact2Modal setOpen={(state: boolean) => setContact2Open(state)}/> }
            { projectsOpen && <ProjectsModal setOpen={(state: boolean) => setProjectsOpen(state)}/> }
            { shutdownOpen && <BSoD /> }

            {
                rightClick.show && <RightClick x={rightClick.x} y={rightClick.y} />
            }

            {/* If using desktop, show modal that eagers the user to see projects catalog */}
            {
                (!isMobile && seeMoreOpen) && <SeeMore setOpen={() => setSeeMoreOpen(false)} />
            }

            <TaskBar
                list={
                    <List>

                        {/* Contact */}
                        <List.Item
                            icon={<Icwdial102 variant="32x32_4" />}
                            onClick={() => {
                                setContactOpen(true)
                            }}
                            
                        >
                            The Syntax of Me
                        </List.Item>


                        {/* Projects */}
                        <List.Item
                            icon={<Winfile1 variant="32x32_4" />}
                            onClick={() => {
                                setProjectsOpen(true)
                            }}
                        >
                            Projects
                        </List.Item>


                        {/* Email contact */}
                        <List.Item
                            icon={<Awfxcg321301 variant="32x32_4" />}
                            onClick={() => {
                                setContact2Open(true)
                            }}
                        >
                            Contact
                        </List.Item>


                        {/* GitHub */}
                        <List.Item
                            icon={<img src={github_img} alt="GitHub" style={{
                                height: '40px',
                            }} />}
                            onClick={() => {
                                window.open('https://github.com/komppa', '_blank', 'noreferrer')
                            }}
                        >
                            GitHub
                        </List.Item>


                        <List.Divider />
                      

                        {/* Shutdown */}
                        <List.Item
                            icon={<Computer2 variant="32x32_4" />}
                            onClick={() => setTimeout(() => pleaseBsod(), 1500)}
                        >
                            Shut Down...
                        </List.Item>

                    </List>
                }
            />

        </ThemeProvider>
    )


}
    
export default App
